import { palette } from './palette'

export const darkContextualBorderPalette = {
  'border/on-major': `inset 0px 0px 0px 1px ${palette['neutral-light-12']}`,
  'border/action-focused-moderate': `inset 0 -2px 0 ${palette['white-100']}`,
  'border/action-underline-major': `inset 0px -2px 0px ${palette['purple-light-24']}`,
  'border/action-underline-major-hover': `inset 0px -2px 0px ${palette['purple-light-100']}`,
  'border/action-underline-minor': `inset 0px -2px 0px ${palette['neutral-light-24']}`,
  'border/action-underline-minor-hover': `inset 0px -2px 0px ${palette['neutral-light-80']}`,
  'border/action-underline-caution': `inset 0px -2px 0px ${palette['red-light-24']}`,
  'border/action-underline-caution-hover': `inset 0px -2px 0px ${palette['red-light-100']}`,
  'border/row': `inset 0px -1px 0px ${palette['neutral-light-8']}`,
  'border/row-top': `inset 0px 1px 0px ${palette['neutral-light-8']}`,
  'border/cell': `inset -1px 0px 0px ${palette['neutral-light-8']}`,
  'border/cell-left': `inset 1px 0px 0px ${palette['neutral-light-8']}`,
  'border/cell-sticky-left': `4px 0px 0px ${palette['neutral-light-4']}, inset -1px 0px 0px ${palette['neutral-light-16']}`,
  'border/focus-indicator': `inset 0px 0px 0px 2px ${palette['neutral-light-24']}`,
  'border/focus-indicator-item-selected': `inset 0 0 0 2px ${palette['neutral-light-24']}, inset 2px 0 0 ${palette['purple-light-100']}`,
  'border/select-item': `inset 0px 0px 0px 2px ${palette['neutral-light-24']}`,
  'border/select-item-disabled': `inset 0px 0px 0px 2px ${palette['neutral-light-16']}`,
  'border/horizontal-tab-item-minor-selected': `inset 0px -2px 0px ${palette['neutral-light-100']};`,
  'border/form-item-focused': `inset 0px 0px 0px 1px ${palette['white-4']}, inset 0px -2px 0px ${palette['purple-dark-100']}`,
  'border/popover': `0px 0px 0px 2px ${palette['neutral-light-2']}, inset 0px -1px 0px ${palette['neutral-light-16']}, inset 0px 0px 0px 1px ${palette['neutral-light-12']}`,
  'border/chat-content-overflow': `inset 0px -2px 0px ${palette['white-16']}`,
  'border/list-item': `inset 0px -1px 0px ${palette['neutral-light-12']}`,
  'border/horizontal-nav-item-hovered': `0px -2px 0px 0px ${palette['white-32']} inset`,
  'border/list-item-major-selected': `inset 2px 0 0 ${palette['purple-dark-100']}, inset 0 -1px 0 ${palette['neutral-light-12']}`,
  'border/card-highlighted-success': `0px 0px 0px 4px ${palette['green-dark-12']}, 0px 0px 0px 1px ${palette['green-dark-100']} inset`,
}
