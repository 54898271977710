import { palette } from './palette'

export const darkContextualTextPalette = {
  'text/body-major': palette['white-100'],
  'text/body-moderate': palette['neutral-light-64'],
  'text/list-item': palette['white-100'],
  'text/list-item-selected': palette['white-100'],
  'text/list-item-unselected': palette['neutral-light-100'],
  'text/heading-major': palette['white-100'],
  'text/form-label-major': palette['white-100'],
  'text/form-label-moderate': palette['neutral-light-64'],
  'text/form-value': palette['white-100'],
  'text/form-value-disabled': palette['neutral-light-64'],
  'text/form-placeholder': palette['neutral-light-64'],
  'text/form-success': palette['green-light-100'],
  'text/form-error': palette['red-light-100'],
  'text/action-item': palette['white-100'],
  'text/action-major': palette['purple-light-100'],
  'text/action-moderate': palette['white-100'],
  'text/deprecated-action-moderate': palette['teal-light-100'],
  'text/action-minor': palette['neutral-light-80'],
  'text/action-caution': palette['red-light-100'],
  'text/action-warning': palette['yellow-light-100'],
  'text/action-inform': palette['blue-light-100'],
  'text/action-disabled': palette['neutral-light-64'],
  'text/heading-moderate': palette['neutral-light-64'],
  'text/detail-moderate': palette['neutral-light-64'],
  'text/tab-item-major-selected': palette['orange-dark-100'],
  'text/tab-item-minor-selected': palette['white-100'],
  'text/tab-item-unselected': palette['neutral-light-80'],
  'text/cell': palette['white-100'],
  'text/date-picker-item-today: ': palette['red-light-100'],
}
